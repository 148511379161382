<template>
  <div class="mt-5">
    <div class="vx-col mx-10 mb-base">
      <vs-card class>
        <div class="vx-breadcrumb md:block hidden">
          <ul class="flex flex-wrap items-center">
            <li class="inline-flex items-center">
              <router-link :to="{ name: 'director-learning-centre-list' }"
                >Manage</router-link
              >
              <span class="breadcrumb-separator mx-2">
                <span class="feather-icon select-none relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right"
                  >
                    <polyline points="13 17 18 12 13 7" />
                  </svg>
                </span>
              </span>
            </li>
            <li class="inline-flex items-center">
              <router-link :to="{ name: 'director-learning-centre-list' }"
                >Early Learning Centre</router-link
              >
              <span class="breadcrumb-separator mx-2">
                <span class="feather-icon select-none relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right"
                  >
                    <polyline points="13 17 18 12 13 7" />
                  </svg>
                </span>
              </span>
            </li>

            <li class="inline-flex items-center">
              <router-link
                :to="{
                  name: 'director-learning-centre-view',
                  params: { id: dataId }
                }"
                >{{ companyName }}</router-link
              >
              <span class="breadcrumb-separator mx-2">
                <span class="feather-icon select-none relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right"
                  >
                    <polyline points="13 17 18 12 13 7" />
                  </svg>
                </span>
              </span>
            </li>

            <li class="inline-flex items-center">
              <a href="javascript:void(0)" class>Edit</a>
            </li>
          </ul>
        </div>
        <!-- <vs-divider class="mx-6 w-auto mb-0 md:block hidden"></vs-divider> -->

        <div class="w-full md:w-1/2">
          <div class="vx-col w-full my-8 vs-con-loading__container">
            <template v-if="logo">
              <!-- Image Container -->
              <div class="w-64 flex items-center">
                <img :src="logo" alt="img" class="responsive" />
              </div>

              <!-- Image upload Buttons -->
              <div class="modify-img flex mt-5">
                <input
                  type="file"
                  class="hidden"
                  ref="updateImgInput"
                  @change="updateCurrImg"
                  accept="image/*"
                />
                <vs-button @click="$refs.updateImgInput.click()"
                  >Edit</vs-button
                >
                <vs-button @click="logo = null" class="ml-8" color="danger"
                  >Remove Photo</vs-button
                >
              </div>
            </template>

            <div class="upload-img mt-5" v-if="!logo">
              <input
                type="file"
                class="hidden"
                ref="uploadImgInput"
                @change="updateCurrImg"
                accept="image/*"
              />
              <vs-button @click="$refs.uploadImgInput.click()" type="border"
                >Upload Photo</vs-button
              >
            </div>
          </div>
          <!-- NAME -->
          <vs-input
            label-placeholder="Centre Name"
            v-model="companyName"
            class="w-full mt-5"
            name="companyName"
            v-validate="'required'"
            data-vv-as="Centre Name"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('companyName')"
            >{{ errors.first("companyName") }}</span
          >
          <!-- Age Group -->
          <!--  <div class="vx-col w-full mt-5">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <v-select
                  :options="ageOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="ageFilter"
                  class="mb-4 md:mb-0"
                  data-vv-as="Age Group"
                  required
                >
                  <template #header>
                    <label for class="label">Age Group</label>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <vs-icon icon="arrow_drop_down"></vs-icon>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </div> -->

          <vs-input
            label-placeholder="Phone Number"
            v-model="contactNumber"
            class="mt-5 w-full"
            name="contactNumber"
            v-validate="'required|numeric'"
            data-vv-as="Phone number"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('contactNumber')"
            >{{ errors.first("contactNumber") }}</span
          >

          <vs-input
            class="w-full mt-5"
            name="email"
            v-validate="'required|email'"
            label-placeholder="Email"
            v-model="email"
          />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{
            errors.first("email")
          }}</span>
          <div class="vx-col w-full mt-5">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <v-select
                  :options="countryOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="countryFilter"
                  class="mb-4 md:mb-0"
                  data-vv-as="Country"
                >
                  <template #header>
                    <label for class="label">Country</label>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <vs-icon icon="arrow_drop_down"></vs-icon>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="vx-col w-full mt-5">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <!-- <label class="vs-input--label">State</label> -->
                <v-select
                  :options="stateOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="stateFilter"
                  class="mb-4 md:mb-0"
                  data-vv-as="State"
                >
                  <template #header>
                    <label for class="label">State</label>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <vs-icon icon="arrow_drop_down"></vs-icon>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <vs-input
            label-placeholder="Postcode"
            v-model="postCode"
            class="mt-5 w-full"
            name="postCode"
            v-validate="'required'"
            data-vv-as="Post Code"
          />
          <span class="text-danger text-sm" v-show="errors.has('postCode')">{{
            errors.first("postCode")
          }}</span>

          <div vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full mt-5">
              <input
                type="text"
                label-placeholder="Street Address"
                ref="autocomplete"
                v-validate="'required'"
                data-vv-as="Street Address"
                name="streetAddress"
                v-model="streetAddress"
                class="vs-inputx vs-input--input normal"
                :class="{ hasValue: streetAddress }"
              />
            </div>
          </div>
          <span
            class="text-danger text-sm"
            v-show="errors.has('streetAddress')"
            >{{ errors.first("streetAddress") }}</span
          >

          <vs-input
            label-placeholder="Town/City"
            v-model="town"
            class="mt-5 w-full"
            name="town"
            v-validate="'required'"
            data-vv-as="Town"
          />
          <span class="text-danger text-sm" v-show="errors.has('town')">{{
            errors.first("town")
          }}</span>
        </div>
        <div class="mt-8 w-full md:w-1/2">
          <div class="flex flex-wrap items-center justify-end">
            <vs-button color="danger" @click="cancelBtn">Cancel</vs-button>
            <vs-button class="ml-8" @click="submitData" :disabled="!isFormValid"
              >Save</vs-button
            >
          </div>
        </div>
      </vs-card>
    </div>
  </div>
</template>
<script
  type="text/javascript"
  async
  src="//maps.googleapis.com/maps/api/js?key=AIzaSyD2_dLcglU5iyLUQ9hYiUuvpAyy7iRT3-o&libraries=places"
></script>

<script>
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from "vue-select";
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      dataId: null,
      directorId: "",
      companyName: "",
      contactNumber: "",
      fullName: "",
      email: "",
      logo: null,
      image: null,
      new_upload: "no",
      settings: {
        // perfect scrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      streetAddress: "",
      town: "",
      postCode: "",
      countryOptions: [],
      stateOptions: [],
      ageOptions: [],
      countryFilter: { label: "All", value: "all" },
      stateFilter: { label: "All", value: "all" },
      ageFilter: { label: "Select age group", value: "" },
      customError: "",
      address: {
        displayAddress: "",
        suburb: "",
        state: "",
        street: "",
        streetNumber: "",
        postCode: "",
        location: {
          type: "point",
          coordinates: []
        },
        addressData: []
      },
      checkDisplayAddress: ""
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
    }
  },

  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.companyName &&
        this.contactNumber &&
        /*  && this.fullName */
        this.email &&
        this.logo &&
        this.countryFilter.value != "all" &&
        this.stateFilter.value != "all" &&
        this.streetAddress &&
        this.town &&
        this.postCode /*&&
        this.ageFilter.value != "" */
      );
    },
    director() {
      return this.$store.state.AppActiveUser;
    }
  },
  async mounted() {
    let self = this;
    this.$gmapApiPromiseLazy().then(() => {
      self.autocomplete = new google.maps.places.Autocomplete(
        self.$refs.autocomplete,
        { types: ["geocode"] }
      );
      // , componentRestrictions: { country: "au" }
      self.autocomplete.addListener("place_changed", () => {
        let place = self.autocomplete.getPlace();
        self.errors.remove("streetAddress");
        let ac = place.address_components;
        self.address.addressData = place.address_components;
        self.address.addressData[0].checkDisplayAddress =
          place.formatted_address;
        self.checkDisplayAddress = place.formatted_address;
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();
        self.address.displayAddress = place.formatted_address;
        self.streetAddress = place.formatted_address;
        self.address.location.coordinates[0] = lon;
        self.address.location.coordinates[1] = lat;
      });
    });
  },
  methods: {
    ...mapActions("center", [
      "updateDirectorCenter",
      "addDirectorCenter",
      "imageUpload",
      "getDirectorAllCountries",
      "getDirectorCenterById",
      "getAgeGroup"
    ]),
    place_changed(a) {},
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        this.new_upload = "yes";
        var reader = new FileReader();
        reader.onload = e => {
          this.logo = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    async submitData() {
      await this.$validator.validateAll().then(result => {
        if (result) {
          let data = new FormData();
          data.append("id", this.dataId);
          data.append("companyName", this.companyName);
          data.append("contactNumber", this.contactNumber);
          data.append("directorId", this.director.id);
          data.append("fullName", "");
          data.append("email", this.email);
          data.append("logo", this.image);
          data.append("new_upload", this.new_upload);

          data.append("displayAddress", this.streetAddress);
          let sA = this.streetAddress.split(",");
          data.append("streetAddress", sA[0]);

          data.append("town", this.town);
          data.append("postCode", this.postCode);
          data.append("state", this.stateFilter.value);
          data.append("country", this.countryFilter.value);
          /* data.append("age", this.ageFilter.value); */
          data.append("location", JSON.stringify(this.address.location));

          this.customError = "";
          if (this.dataId !== null) {
            this.$vs.loading();
            this.updateDirectorCenter(data)
              .then(res => {
                if (res.status === 200) {
                  this.showMessage(
                    "Success",
                    "Centre Updated successfully.",
                    "success"
                  );
                  data.country = this.countryFilter.value;
                  this.$emit("refreshData", true);
                }
                this.$vs.loading.close();
              })
              .catch(err => {
                this.$vs.loading.close();
              });
            this.$router.push({
              name: "director-learning-centre-view",
              params: { id: this.dataId }
            });
          } else {
            if (this.logo == null) {
              this.customError = "Image field is required.";
              return;
            }
            this.$vs.loading();
            this.addDirectorCenter(data)
              .then(res => {
                // console.log( res );
                if (res.status === 200) {
                  this.$vs.loading.close();
                  this.$emit("refreshData", true);
                  this.showMessage(
                    "Success",
                    "Centre updated successfully.",
                    "success"
                  );
                }
              })
              .catch(err => {
                this.$vs.loading.close();
                if (err.response.status === "422") {
                  this.showMessage(
                    "Failed",
                    err.response.data.message,
                    "danger"
                  );
                }
                this.showMessage("Failed", "Failed to add centre", "danger");
                // console.error(err)
              });

            this.$router.push({
              name: "director-learning-centre-edit",
              params: { id: this.dataId }
            });
          }
        }
      });
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    cancelBtn() {
      this.$router.push({
        name: "director-learning-centre-view",
        params: { id: this.dataId }
      });
    },
    async getDetails(id) {
      this.$vs.loading();
      let self = this;
      await this.getDirectorCenterById(id)
        .then(async res => {
          let result = await res.data.data;

          self.dataId = result._id;
          self.titleFilter = { label: result.title, value: result.title };
          self.companyName = result.name;
          self.email = result.email;
          self.contactNumber = result.phoneNumber;
          self.directorId = result.directorId;

          self.town = result.address.suburb;
          self.postCode = result.address.postCode;
          self.streetAddress = result.address.displayAddress;

          self.logo = result.logo;
          self.image = result.logo;

          self.stateFilter = {
            label: result.address.state,
            value: result.address.state
          };
          self.countryFilter = {
            label: result.country.name,
            value: result.country._id
          };
          self.ageFilter = {
            label: result.ageDetail[0],
            value: result.ageGroupId
          };

          self.getDirectorAllCountries().then(async res => {
            let filter = {
              label: self.countryFilter.label,
              value: self.countryFilter.label
            };
            let selectedCountry = _.find(res.data.data, filter);

            self.countryFilter = selectedCountry;
            self.stateOptions = await self.countryFilter.states;
          });
          self.$vs.loading.close();
        })
        .catch(err => {
          self.$vs.loading.close();
          //console.error(err)
        });
    }
  },
  created() {
    let id = this.$route.params.id;
    if ("undefined" != typeof id) {
      this.getDetails(id);
    }

    this.getAgeGroup()
      .then(res => {
        this.ageOptions = res;
      })
      .catch(err => {});

    this.getDirectorAllCountries()
      .then(res => {
        // console.log( res );
        this.countryOptions = this.$store.state.center.countryOptions;
      })
      .catch(err => {
        // console.error(err)
      });
  },
  components: {
    vSelect
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
